<template>
    <section class="section section section-shaped my-0 overflow-hidden automate-tasks">
        <div class="shape shape-style-1 bg-gradient-warning shape-skew">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container py-0">
            <div class="row row-grid align-items-center">
                <div class="col-md-6 order-lg-2 ml-lg-auto">
                    <div class="position-relative pl-md-5">
                        <img src="img/ill/ill-2.svg" class="img-center img-fluid">
                    </div>
                </div>
                <div class="col-lg-6 order-lg-1">
                    <div class="d-flex px-3">
                        <div>
                            <icon name="ni ni-building" size="lg" 
                                  class="bg-gradient-white" color="primary" shadow rounded></icon>
                        </div>
                        <div class="pl-4">
                            <h4 class="display-3 text-white">Automatize tarefas</h4>
                            <p class="text-white">A maioria das tarefas administrativas e contábeis são executadas manualmente, mas podem ser automatizadas.</p>
                        </div>
                    </div>
                    <card shadow class="shadow-lg--hover mt-5">
                        <div class="d-flex px-3">
                            <div>
                                <icon name="ni ni-satisfied" gradient="success" color="white" shadow
                                      rounded></icon>
                            </div>
                            <div class="pl-4">
                                <h5 class="title text-success">Agilidade</h5>
                                <p>Tarefas realizadas no dia a dia, processos que demorariam horas para serem feitos, podem ser concluídos em minutos.</p>
                                 <!--<a href="#" class="text-success">Learn more</a> -->
                            </div>
                        </div>
                    </card>
                    <!--<card shadow class="shadow-lg--hover mt-5">
                        <div class="d-flex px-3">
                            <div>
                                <icon name="ni ni-active-40" gradient="warning" 
                                      color="white" shadow rounded></icon>
                            </div>
                            <div class="pl-4">
                                <h5 class="title text-warning">Economia</h5>
                                <p>Contrate conscientemente, funcionários são necessários quando há 
                                    demanda real de seus serviços.</p>
                                  <a href="#" class="text-warning">Learn more</a>
                            </div>
                        </div>
                    </card>-->
                    <card shadow class="shadow-lg--hover mt-5">
                        <div class="d-flex px-3">
                            <div>
                                <icon name="ni ni-satisfied" gradient="success" color="white" shadow
                                      rounded></icon>
                            </div>
                            <div class="pl-4">
                                <h5 class="title text-success">Segurança</h5>
                                <p>Dados são passados à máquinas utilizando criptografia e jamais serão fornecidos a terceiros.</p>
                                <!-- <a href="#" class="text-success">Learn more</a> -->
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.automate-tasks {

}
</style>