<template>
  <section class="position-relative component-header-landing">
  <!-- shape Hero -->
  <section class="section-shaped my-0">
    <div class="shape shape-style-1 shape-default shape-skew">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    </div>
    <div class="container col-11 shape-container d-flex">
      <div class="col px-0">
        <div class="row">
          <div class="col mt-5">
            <div class="mb-3">
              <h1 class="display-2 text-white mb-0">
              Criação de Banco Digital & Soluções Financeiras
              <!-- <span></span> -->
              </h1>
              <p class="text-white text-boldered" style="font-size: 1.3rem; font-weight: 600;">
                Damos toda a assessoria necessária para a criação do seu banco digital.
              </p>
            </div>
            <div>
              <p class="lead text-white mt-0 text-justify mx-auto col-11">
              Solução moderna e construída sobre pradrões de mercado, o Bairon entrega todos os elementos necessários para uma integração rápida e segura.
              </p>
            </div>
            <!-- <div class="btn-wrapper">
              <base-button
                tag="a"
                href="#formContatUs"
                class="mb-3 mb-sm-0"
                type="white"
                icon="fa fa-id-card">
                Contato
              </base-button>
              <base-button
                tag="a"
                href="#carouselProjects"
                class="mb-3 mb-sm-0"
                type="white"
                icon="fa fa-briefcase">
                Projetos
              </base-button>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col order-lg-2 ml-lg-auto">
        <div class="position-relative pl-md-5">
          <img
            src="/image/computer-money.png"
            class="img-center img-fluid floating"
            style="
              /* margin-top: -63px; */
              width: auto;
              height: 300px;
            "/>
        </div>
      </div>
      <div class="arrow animated bounce row">
        <i class="fa fa-angle-double-down fa-3x p-0" @click="$emit('scrollToFirst')"></i>
      </div>
    </div>
  </section>
  <!-- 1st Hero Variation -->
  </section>
</template>

<script>
export default {
  name: 'HeaderLanding'
};
</script>

<style lang="scss" scoped>
.component-header-landing {
  @-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      -moz-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -moz-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -moz-transform: translateY(-10px);
      transform: translateY(-10px);
    }
  }

  @-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-25px);
      transform: translateY(-25px);
    }
    60% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -moz-transform: translateY(-25px);
      -ms-transform: translateY(-25px);
      -webkit-transform: translateY(-25px);
      transform: translateY(-25px);
    }
    60% {
      -moz-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
  }
  .arrow {
    position: fixed;
    bottom: 5vh;
    left: 50%;
    margin-left: -20px;
    width: 20px;
    height: 20px;
    color: #a8a8a8;
    background-size: contain;
  }

  .bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }

  .fade {
    opacity:0;
    transition: 1s all ease-out;
  }

  .animated i::before {
    cursor: pointer;
  }
}
</style>
