<template>
  <section class="section section-lg scoped-application">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-md-2">
          <img src="img/theme/promo-1.png" class="img-fluid floating">
        </div>
        <div class="col-md-6 order-md-1">
          <div class="pr-md-5">
            <icon name="ni ni-settings-gear-65" class="mb-5" size="lg" type="success" shadow
                rounded></icon>
            <h3 v-html="title" />
            <p v-html="text" />
            <ul class="list-unstyled mt-5">
              <li
                class="py-2"
                v-for="(item, index) in topics"
                :key="index">
                <div class="d-flex align-items-center">
                  <badge type="success" circle class="mr-3" :icon="`ni ni-${item.icon}`"></badge>
                  <h6 class="mb-0">{{item.text}}</h6>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.scoped-application {

}
</style>
