<template>
    <section class="section section-lg">
        <div class="container">
            <div class="row text-center justify-content-center">
                <div class="col-lg-10">
                    <h2 class="display-3">Controle seu próprio negócio</h2>
                    <p class="lead">Com as nossas tecnologias você pode publicar, editar e atualizar seu site.</p>
                </div>
            </div>
            <div class="row row-grid mt-5">
                <div class="col-lg-4">
                    <icon name="ni ni-settings" size="lg" gradient="white" shadow round color="primary"></icon>
                    <h5 class=" mt-3">Ferramentas</h5>
                        <p class="mt-3">Atualize o conteúdo do site facilmente com as ferramentas fornecidas.</p>
                </div>
                <div class="col-lg-4">
                    <icon name="ni ni-ruler-pencil" size="lg" gradient="white" shadow round color="primary"></icon>
                    <h5 class="mt-3">SEO automático</h5>
                    <p class="mt-3">Todo conteúdo atualizado será automaticamente encontrado pelos usuários.</p>
                </div>
                <div class="col-lg-4">
                    <icon name="ni ni-atom" size="lg" gradient="white" shadow round color="primary"></icon>
                    <h5 class="mt-3">Publicação rápida</h5>
                    <p class="mt-3">Editou, salvou, publicou. Atualização instantânea, os novos dados são enviados a um clique. Simples e rápido.</p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
components: {}  
};
</script>