<template>
  <div>
    <base-product
      v-for="(product, index) in products"
      :key="index"
      v-bind="product"/>
  </div>
</template>

<script>
export default {
  name: 'OurProducts',
  data () {
    return {
      products: [
        {
          id: 'bankBillPayment',
          title: 'Emissão de Boletos Bancários',
          text: 'Emita boletos de cobraça para pessoas físicas ou jurídicas, como contribuintes, empresas, etc.',
          icon: 'list-alt',
          color: 'primary',
          imagePath: '/image/bank-bill.jpg',
          topics: [
            {
              text: 'Crie cobranças para pessoas físicas ou jurídicas.',
              type: 'primary',
              icon: 'briefcase'
            },
            {
              text: 'Tenha autonomia para gerenciar seus pagamentos.',
              type: 'primary',
              icon: 'usd'
            },
            {
              text: 'Facilidade para emissão de boletos.',
              type: 'primary',
              icon: 'file-text-o'
            }
          ]
        },
        {
          id: 'bankTransfers',
          title: 'Transferência TED e DOC',//'Transferência Bancária',
          text: 'Envie e receba TED e DOC direto em sua conta bancária.',
          icon: 'exchange',
          color: 'success',
          imagePath: '/image/bank-transfers.jpg',
          topics: [
            {
              text: 'Envie TED e DOC com os custos mais acessíveis do mercado.',
              type: 'success',
              icon: 'usd'
            },
            {
              text: 'Receba TED e DOC diretamente em sua conta bancária.',
              type: 'success',
              icon: 'file-text-o'
            },
            {
              text: 'Gerencie os limites de TED e DOC.',
              type: 'success',
              icon: 'briefcase'
            }
          ]
        },
        {
          id: 'bankPix',
          title: 'Pix',
          text: 'PIX é um sistema de pagamento digital que permite que você e sua empresa envie e receba 24 horas por dias e 7 dias por semana.',
          icon: 'th-large',
          color: 'info',
          iconStyle: 'rotate: 45deg; color: var(--default)',
          imagePath: '/image/bank-pix.png',
          topics: [
            {
              text: 'Agilidade para todas as operações com o PIX.',
              type: 'info',
              icon: 'th-large'
            },
            {
              text: 'Disponibilidade para uso a qualquer momento.',
              type: 'info',
              icon: 'clock-o'
            },
            {
              text: 'Chaves exclusivas para cada pessoa.',
              type: 'info',
              icon: 'key'
            },
            {
              text: 'Aceito em lojas físicas e virtuais.',
              type: 'info',
              icon: 'shopping-cart'
            }
          ]
        },
        {
          id: 'bankTaxPayments',
          title: 'Pagamento de Impostos',
          text: 'Nossos serviços incluem facilidade no pagamento de tarifas de concessionária tais como agua, luz, telefone, IPVA, IPTU, DARF entre outros.',
          icon: 'usd',
          color: 'warning',
          imagePath: '/image/bank-tax-payments.jpg',
          topics: [
            {
              text: 'Controle os pagamentos de impostos ou outras taxas.',
              type: 'warning',
              icon: 'usd'
            },
            {
              text: 'Facilidades para fazer buscas e ajuda com o imposto de renda.',
              type: 'warning',
              icon: 'search'
            }
          ]
        },
        {
          id: 'bankCard',
          title: 'Cartão',
          text: 'Personalize seu cartão com a identidade visual do seu negócio.',
          icon: 'credit-card',
          color: 'danger',
          imagePath: '/image/bank-card.jpg',
          topics: [
            {
              text: 'Faça compras e saques.',
              type: 'danger',
              icon: 'credit-card'
            },
            {
              text: 'Gerencie seus cartões.',
              type: 'danger',
              icon: 'briefcase'
            },
            {
              text: 'Tenha acesso a todas as funcionalidades do cartão.',
              type: 'danger',
              icon: 'file-text-o'
            }
          ]
        },
        {
          id: 'bankMobileApp',
          title: 'Aplicativo Mobile',
          text: 'De mais poder ao seu negócio com o aplicativo mobile, adpta-se ao seu negócio e ao seu cliente.',
          icon: 'mobile',
          color: 'primary',
          imagePath: '/image/bank-mobile-app.jpg',
          topics: [
            {
              text: 'Peronalize seu aplicativo com a identidade visual do seu negócio.',
              type: 'primary',
              icon: 'briefcase'
            },
            {
              text: 'Ofereça uma tecnologia de ponta para seu negócio.',
              type: 'primary',
              icon: 'mobile'
            }
          ]
        },
        {
          id: 'bankInternetBanking',
          title: 'Internet Banking',
          text: 'Com o seu Internet Banking, o cliente correntista não precisará ir a uma agencia física, ou seja, ele poderá fazer todas as operações bancárias de forma online, rapidamente e com segurança e tudo isso sem sair de casa.',
          icon: 'internet-explorer',
          color: 'success',
          imagePath: '/image/bank-internet-banking.jpg',
          topics: [
            {
              text: 'Acesse seu conta de qualquer lugar.',
              type: 'success',
              icon: 'internet-explorer'
            },
            {
              text: 'Mais autonomia para seu negócio.',
              type: 'success',
              icon: 'briefcase'
            }
          ]
        },
        {
          id: 'bankManagementPanel',
          title: 'Painel de Gerenciamento',
          text: 'O painel de gerenciamento é um aplicativo que permite que você gerencie seus clientes, contas, cartões, taxas e outras informações.',
          icon: 'cogs',
          color: 'info',
          imagePath: '/image/bank-management-panel.png',
          topics: [
            {
              text: 'Controle as taxas de seu negócio.',
              type: 'info',
              icon: 'usd'
            },
            {
              text: 'Tenha o poder de aprovação de conta e cartão.',
              type: 'info',
              icon: 'check'
            },
            {
              text: 'Gerencie as transações da empresa.',
              type: 'info',
              icon: 'file-text-o'
            },
            {
              text: 'Obtenha os relatórios de sua empresa.',
              type: 'info',
              icon: 'file-text-o'
            }
          ]
        },
        {
          id: 'bankIntegrationOurPlatforms',
          title: 'Integração com nossas plataformas',
          text: 'Oferecemos o meio para adptar nossos meios de pagamentos dentro de sua plataforma, permitindo que você tenha os melhores resultados para seu negócio.',
          icon: 'link',
          color: 'warning',
          imagePath: '/image/bank-integration-our-platforms.png',
          topics: [
            {
              text: 'Personalização',
              type: 'warning',
              icon: 'link'
            },
            {
              text: 'Integração total com seu sistema',
              type: 'warning',
              icon: 'laptop'
            },
            {
              text: 'Flexibilidade para escolher os serviços que deseja',
              type: 'warning',
              icon: 'cogs'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style>

</style>