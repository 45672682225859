<template>
  <div>
    <Header-Landing
      @scrollToFirst="scrollToFirst"
      id="headerLanding"/>
    <Product-Type 
      id="productType"/>
    <Our-Products />
    <Message-Contact-Us />
    <section class="section section-lg section-shaped overflow-hidden my-0">
      <div class="shape shape-style-3 shape-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <Contact />
    </section>
  </div>
</template>

<script>
import {
  HeaderLanding,
  ProductType,
  ScopedApplication,
  UserFeedback,
  AutomateTasks,
  OwnControl,
  CarouselProjects,
  MessageContactUs,
  TechnologiesWeWork,
  AboutUs,
  BankBillPayment,
  BankCard,
  BankTransfers,
  BankPix,
  BankSlip,
  OurProducts
} from '@/components/Banking';

import {Contact} from "@/components"

export default {
  name: 'LandingBody',
  components: {
    HeaderLanding,
    ProductType,
    ScopedApplication,
    UserFeedback,
    AutomateTasks,
    OwnControl: OwnControl,
    CarouselProjects,
    MessageContactUs,
    TechnologiesWeWork,
    Contact,
    AboutUs,
    BankBillPayment,
    BankCard,
    BankTransfers,
    BankPix,
    BankSlip,
    OurProducts
},
  methods: {
    scrollToFirst() {
      window.scrollTo({
        top: document.querySelector('#productType').offsetTop - 165,
        behavior: 'smooth',
        duration: 1000
      })
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
  },
  mounted () {
    const scroll = document.querySelector('.animated')
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        scroll.classList.add('fade')
      } else {
        scroll.classList.remove('fade')
      }
    })
    this.scrollToTop();
  }
};
</script>
