<template>
    <section class="section bg-secondary user-feedback">
        <div class="container">
            <div class="row row-grid align-items-center">
                <div class="col-md-6">
                    <div class="card bg-default shadow border-0">
                        <img v-lazy="'img/theme/img-1-1200x1000.jpg'" class="card-img-top">
                                     <!-- mapa-de-calor-por-cliques.svg -->
                        <blockquote class="card-blockquote">
                            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                                class="svg-bg">
                                <polygon points="0,52 583,95 0,95" class="fill-default"></polygon>
                                <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-default"></polygon>
                            </svg>
                            <h4 class="display-3 font-weight-bold text-white">Atualização inteligente</h4>
                            <p class="lead text-italic text-white">
                                Para manter seu negócio em constante atualização com o mercado,
                                disponibilizamos métodos para captar como seus usuários interagem.
                            </p>
                        </blockquote>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="pl-md-5">
                        <icon name="ni ni-settings" class="mb-5" size="lg" type="warning" shadow rounded></icon>
                        <h3>Kit Feedback Inteligente</h3>
                        <p class="lead">Ideal para:</p>
                        <ul>
                            <li>Entender como o usuário interage</li>
                            <li>Onde há mais cliques</li>
                            <li>Páginas mais visualizadas</li>
                            <li>Fluxos mais realizados</li>
                        </ul>
                        <a href="#" class="font-weight-bold text-warning mt-5">
                            Para impactar o usuário positivamente é necessário 
                            entender suas necessidades.
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.user-feedback {

}
</style>
