<template>
    <section class="section section-lg section-shaped overflow-hidden my-0 carousel-projects" id="carouselProjects">
        <div class="shape shape-style-1 shape-default shape-skew">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container py-0 pb-lg">
            <div class="row justify-content-between align-items-center">
                <div class="col-lg-5 mb-5 mb-lg-0">
                    <h1 class="text-white font-weight-light">Projetos Recentes</h1>
                    <p class="lead text-white mt-4">
                       O Bairon tem o prazer em apresentar ao lado uma das ótimas ideias que ajudamos a tirar do papel recentemente. Obrigado a todos os envolvidos.
                    </p>
                    <a v-if="false" href="#projects-page" class="btn btn-white mt-4">Ver todos os Projetos</a>
                </div>
                <div class="col-lg-6 mb-lg-auto">
                    <div class="rounded shadow-lg overflow-hidden transform-perspective-right">
                        <b-carousel id="carousel1" controls indicators>
                            <!-- Text slides with image -->
                            <b-carousel-slide img-src="img/theme/img-1-1200x1000.jpg">
                                <h1>Brasil Transportes S.A</h1>
                            </b-carousel-slide>
                        </b-carousel>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {BCarousel} from "bootstrap-vue";
import {BCarouselSlide} from "bootstrap-vue";

export default {
    components: {
        BCarousel,
        BCarouselSlide
    }
};
</script>

<style lang="scss">
.carousel-projects {
    .carousel-caption {
        background-color: #f0f8ff78;
        border-radius: 5px;
    }
}
</style>
