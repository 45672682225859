<template>
  <section class="section section-lg pt-lg-0 product-type mt--200">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="row row-grid">
            <div class="col-lg-4" style="height: 350px!important;">
              <card class="border-0 h-100" hover shadow body-classes="py-5">
                <icon name="ni ni-check-bold" type="primary" rounded class="mb-4">
                </icon>
                <h6 class="text-primary text-uppercase">Banco digital white label</h6>
                <p class="description mt-3 text-justify">
                  É um banco personalizável com as cores e marcas da sua empresa. Tudo é feito com sua marca na conta no aplicativo e no cartão.
                </p>
                <!-- <div>
                  <badge type="primary" rounded>clientes</badge>
                  <badge type="primary" rounded>visibilidade</badge>
                  <badge type="primary" rounded>explore</badge>
                </div> -->
                <!--<base-button tag="a" href="#" type="primary" class="mt-4">
                  Learn more
                </base-button>-->
              </card>
            </div>
            <div class="col-lg-4" style="height: 350px!important;">
              <card class="border-0 h-100" hover shadow body-classes="py-5">
                <icon name="ni ni-istanbul" type="success" rounded class="mb-4">
                </icon>
                <h6 class="text-success text-uppercase">Emissão de boletos</h6>
                  <p class="description mt-3">
                    Emita boletos de cobrança com sua marca.
                  </p>
                  <!-- <div>
                    <badge type="success" rounded>mobile</badge>
                    <badge type="success" rounded>android</badge>
                    <badge type="success" rounded>ios</badge>
                  </div> -->
                  <!--<base-button tag="a" href="#" type="success" class="mt-4">
                    Learn more
                  </base-button>-->
                </card>
            </div>
            <div class="col-lg-4" style="height: 350px!important;">
              <card class="border-0 h-100" hover shadow body-classes="py-5">
                <icon name="ni ni-planet" type="warning" rounded class="mb-4">
                </icon>
                <h6 class="text-warning text-uppercase">Serviços exclusivos</h6>
                <ul style="list-style: none;" class="pl-1">
                  <li>- Cartão personalizável</li>
                  <li>- Extrato</li>
                  <li>- Depósito Bancário</li>
                  <li>- Saque (Saque 24h: Cartão e Digital) e muito mais.</li>
                </ul>
                <!-- <div>
                  <badge type="warning" rounded>segurança</badge>
                  <badge type="warning" rounded>dados</badge>
                  <badge type="warning" rounded>integrações</badge>
                </div> -->
                <!--<base-button tag="a" href="#" type="warning" class="mt-4">
                  Learn more
                </base-button>-->
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.product-type {
}
</style>
