<template>
  <section class="component-about-us">
    <div class="row col-12 mx-0 text-center">
      <h1 class="text-center col-12">
        Sobre nós
      </h1>
      <div class="row align-items-center">
        <div class="col-md-5">
          <img src="/image/contract-img.png" alt="" style="width: 230px; height: 330px;"/>
        </div>
        <div class="col">
          <h4>Somos uma empresa especializada em criação de bancos e fintechs.</h4>
          <p>
            Deixamos tudo pronto! Plataforma segura, serviços bancários, legislação junto ao banco central.
            Falta apenas customizar com suas cores e sua marca. Qualquer empresa pode oferecer soluções financeiras
            digitais, mas apenas nós podemos lhe oferecer um banco com sua cara e cartão personalizável.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>

<style>

</style>