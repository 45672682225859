<template>
  <section class="section section-lg scoped-application">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-md-2">
          <img src="img/theme/promo-1.png" class="img-fluid floating">
        </div>
        <div class="col-md-6 order-md-1">
          <div class="pr-md-5">
            <icon name="ni ni-settings-gear-65" class="mb-5" size="lg" type="success" shadow
                rounded></icon>
            <h3>Aplicações sob medida</h3>
            <p>
              Soluções customizadas, atendendo necessidades específicas com ambientes e 
              tecnologias diversas. Entendemos os problemas a fundo e criamos as melhores 
              soluções que combinam a melhor experiência do usuário com as necessidades de negócio.
              A aplicação pode ser integrada com outras tecnologias.</p>
            <ul class="list-unstyled mt-5">
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <badge type="success" circle class="mr-3" icon="ni ni-settings-gear-65"></badge>
                  <h6 class="mb-0">Personalizável</h6>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <badge type="success" circle class="mr-3" icon="ni ni-html5"></badge>
                  <h6 class="mb-0">Integração com API's externas.</h6>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <badge type="success" circle class="mr-3" icon="ni ni-satisfied"></badge>
                  <h6 class="mb-0">Layout intuitivo</h6>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.scoped-application {

}
</style>
